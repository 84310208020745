import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {API_URL2} from "../../utils/constants";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {email as sendEmail} from "../../utils/apis";
import EmailTemplate from "../../emails/InformEmail";

const MySwal = withReactContent(Swal)

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [comment, setComment] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit= () =>{
    // check and update record
    fetch(`${API_URL2}/travelrequests/${props.match.params.id}`).then((res) => res.json()
  ).then(res => {
    if(res.status === "0" || res.status === "1"){
      let data;
      if (props.match.params.level === "mgt"){
        data = {
          status: "3",
          mgtComments: comment,
          mgtAction: new Date().toISOString()
        }
      } else {
        data = {
          status: "4",
          hrComments: comment,
          hrAction: new Date().toISOString(),
          hr: props.match.params.mail
        }
      }
        fetch(`${API_URL2}/travelrequests/${props.match.params.id}`, {
          method: "PUT",
          body: JSON.stringify(data)
        }).then(res=> res.json()).then(res => {
          // send email
          if (res.email) {
            return sendEmail(
              "e-workflow@aapico.com",
              "Out of Province Travel Request",
              res.email,
              `${props.match.params.level === "mgt"?"[Rejected by MGR]":"[Rejected by HR]"} Out of Province Travel Request`,
              EmailTemplate({...res, destination: JSON.parse(res.destination),
              note: props.match.params.level === "mgt"?"[Rejected by MGR]":"[Rejected by HR]"
            })
            )
          }
          return res.json()
        }).then(res => {
          MySwal.fire({
            title: 'Action Completed!',
            text: 'You have REJECTED the request!',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false
          })
        }).then(res => {
          if (props.match.params.level === "mgt"){
            return props.history.replace({ pathname: "/c/action/"})
          } else {
            return props.history.replace({ pathname: "/c/action/"})
          }
        })
      } else {
        MySwal.fire({
          title: 'Action Error!',
          text: 'This request cannot be REJECTED',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false
        })
        return props.history.replace({ pathname: "/c/action/"})
      }
    })
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please give comment!!!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="comment"
            type="text"
            fullWidth
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="secondary">
            REJECT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}