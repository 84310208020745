import Cookies from 'universal-cookie';
import {server, API_URL2} from "./constants";
import _ from "lodash";


const cookies = new Cookies();

export const login = async (username, password) => {
    console.log('did call');
    return await fetch(`${server}/auth/local`, {
        method: "POST",
        body: JSON.stringify({
            identifier: username.toUpperCase(),
            password
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json()).then(res => 
    { 

        // if (!!res.user && !res.user.custom_role){
        //     return "user not found";
        // } 

        // if (!!res.user && !!res.user.custom_role){
        //     // console.log(_.has(res.user.custom_role, "checkin"));
        //     if(!_.has(res.user.custom_role, "checkin")){
        //         return "user not found";
        //     } else {
        //         cookies.set("jwt", res.jwt)
        //         cookies.set("userID", res.user.empID)
        //         cookies.set("role", JSON.stringify(res.user.custom_role))
        //         return "success"
        //     }
        // } 

        if (res.error ){
            return res.error;
        } else {
            cookies.set("jwt", res.jwt)
            cookies.set("userID", res.user.empID)
            cookies.set("company", res.user.company)
            cookies.set("role", JSON.stringify(res.user.custom_role))
            return "success"
        }

    });
}

export const isLogin = () => {
    let jwt = cookies.get('jwt');
    console.log(jwt);
    if (jwt) return true;
    return false;
}

export const email = (from, fromDetail, to, subject, body) => {
    // set email to manager
    let emailHeaders = new Headers();
    emailHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("form", from);
    urlencoded.append("formdetail", fromDetail);
    urlencoded.append("to", to);
    urlencoded.append("subject", subject);
    urlencoded.append("body", body);
    
    var requestOptions = {
        method: 'POST',
        headers: emailHeaders,
        body: urlencoded,
        redirect: 'follow'
    };
        
    fetch(`${API_URL2}/email`, requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

}


