import React, { Fragment, Suspense, lazy } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch, Redirect, HashRouter, createBrowserHistory  } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
import {AppContext} from './DataContext';
import SuccessfulComponent from "./shared/components/SuccessfulAlert";
import RejectComponent from "./shared/components/RejectAlert";
import "./App.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

const PrivateRoute = ({component: Component, ...rest}) => {
  console.log(!!cookies.get('jwt'));
  return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route {...rest} render={props => (
          !!cookies.get('jwt') ?
              <Component {...props} />
          : <Redirect to="" />
      )} />
  );
};

function App() {
  const [data, setData] = React.useState(null);
  const [selectedID, setSelectedID] = React.useState(null);


  const getData = (data) => {
    setData(data)
  }
  const getSelectedID = (data) => {
    setSelectedID(data)
  }

  return (
    <AppContext.Provider value={{data, selectedID, getData, getSelectedID}} >
    {/* <BrowserRouter basename="location-check/#/"> */}
    <BrowserRouter basename="#">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Pace color={theme.palette.primary.light} />
        <Suspense fallback={<Fragment />}>
            <Switch>          
                  <PrivateRoute path="/c" component={LoggedInComponent}/>
                  <Route exact path="/approve/:id/:uuid/:level/:mail" component={SuccessfulComponent}/>
                  <Route exact path="/reject/:id/:uuid/:level/:mail" component={RejectComponent}/>
                  <Route exact path="/approve/:id/:uuid/:level" component={SuccessfulComponent}/>
                  <Route exact path="/reject/:id/:uuid/:level" component={RejectComponent}/>
                  <Route >
                      <LoggedOutComponent />
                  </Route>
            </Switch>
        </Suspense>
      </MuiThemeProvider>
    </BrowserRouter>
    </AppContext.Provider>
  );
}

serviceWorker.register();

export default App;
